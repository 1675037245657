import { TableCell } from "@material-ui/core";
import styled from "styled-components";

const cellColors = {
  loading: "#D6CE93",
  completed: "var(--primary)",
  incomplete: "#DA627D",
  error: "#332E3C",
};

const ColoredTableCell = styled(TableCell)`
  ${(props: { color?: "loading" | "completed" | "incomplete" | "error" }) => {
    return props.color ? `background-color: ${cellColors[props.color]};` : "";
  }}
`;

export default ColoredTableCell;
