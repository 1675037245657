import React, { useRef, useState } from "react";

import { MenuItem, Select, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Compressor from "compressorjs";
import styled from "styled-components";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import CheckCircleGreenFilledIcon from "~/icons/CheckCircleGreenFilledIcon";
import PlusCircleBlueIcon from "~/icons/PlusCircleBlueIcon";
import TrashIcon from "~/icons/TrashIcon";
import medicalOrderService from "~/utils/api/v1/medicalOrderService";
import { COUNTRIES } from "~/utils/data/constants";
import { Locale } from "~/utils/interfaces/Locale";
import { MedicalOrderRequest, MedicalOrderRequestError } from "~/utils/interfaces/MedicalOrder";

export const UploadItem = styled.div`
  width: full;
  padding: 5px;
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  background-color: #edfff9;
  border-radius: 15px;
`;

function UploadMedicalOrderRequest() {
  const [fileArray, setFileArray] = useState<File[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [messageErrors, setMessageErrors] = useState<MedicalOrderRequestError>({
    name: null,
    phone: null,
    email: null,
  });

  const [medicalOrderRequest, setMedicalOrderRequest] = useState<MedicalOrderRequest>({
    name: "",
    email: "",
    phone: "",
    country: "Chile",
    triggered_from: "betty-request",
    sales_source: "betty-request",
  });

  const validateFields = (): boolean => {
    const messageErrors: MedicalOrderRequestError = {
      name: null,
      phone: null,
      email: null,
    };
    if (medicalOrderRequest.name.trim().length < 2) {
      messageErrors.name = "Debes ingresar un nombre";
    }
    if (/\+?[0-9]{8,13}/.test(medicalOrderRequest.phone) === false) {
      messageErrors.phone = "Debes ingresar un teléfono válido para contactar";
    }
    if (medicalOrderRequest.email.trim().length > 0 && !medicalOrderRequest.email.includes("@")) {
      messageErrors.email = "Debes ingresar un email válido para contactar";
    }

    setMessageErrors(messageErrors);

    return messageErrors.name != null || messageErrors.phone != null;
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (event.target.files && event.target.files.length === 1 && fileArray) {
      const fileToUpload = event.target.files[0];
      if (fileToUpload) {
        if (fileToUpload.size > 15_000_000 && fileToUpload.type != "application/pdf") {
          new Compressor(fileToUpload, {
            quality: 0.2,
            success(result: File) {
              const compressedFile = new File([result], result.name);
              setFileArray([...fileArray, compressedFile]);
            },
          });
        } else if (fileToUpload.size > 15_000_000 && fileToUpload.type === "application/pdf") {
          Swal.fire({
            icon: "error",
            title:
              "Lo sentimos, no podemos procesar archivos pdf de más de 15mb. Prueba subiendo una foto o contactándonos por chat",
          });
        } else {
          setFileArray([...fileArray, fileToUpload]);
        }
      }
    }
    setLoading(false);
  };

  const renderFileUploadProgressBar = () => (
    <div>
      {fileArray?.map((file: File, i: number) => (
        <UploadItem key={i}>
          <Flex gap="1rem">
            {!loading && <CheckCircleGreenFilledIcon />}
            <div className={clsx(loading ? "text-secondary" : "text-success", "flex gap-x-2")}>
              {loading ? "Subiendo" : "Completado"}
            </div>
          </Flex>
          <div>{file.name.substring(0, 100)}...</div>

          {!loading && (
            <Button
              color="default"
              onClick={() => removeUploadedFile(i)}
            >
              <TrashIcon color="#BDBDBD" />
            </Button>
          )}
        </UploadItem>
      ))}
      <Button
        color="primary"
        variant="text"
        onClick={handleClick}
      >
        Subir otro archivo
        <PlusCircleBlueIcon
          width={20}
          height={20}
          className="mx-1"
          fill="#2F80ED"
        />
      </Button>
      <input
        type="file"
        accept="application/pdf, image/*"
        disabled={loading}
        onChange={handleFileInputChange}
        ref={inputRef}
        hidden
      />
    </div>
  );

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const removeUploadedFile = (index: number) => {
    setFileArray((prevState) => prevState?.filter((_, idx) => idx !== index));
  };

  const cleanErrors = () => {
    setError([]);
    setMessageErrors({
      name: null,
      phone: null,
      email: null,
    });
  };

  const handleUploadData = async () => {
    setLoading(true);
    const valErrors = validateFields();

    if (valErrors) {
      setLoading(false);
      return;
    }

    medicalOrderService
      .createRequest(medicalOrderRequest, fileArray)
      .then(() => {
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        setError([`Hubo un error: ${error.response?.data?.err}`]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        {loading ? "Cargando..." : "Ingresar nueva solicitud de orden médica"}
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <DialogTitle>Nueva Solicitud de Orden Médica</DialogTitle>
        <DialogContent>
          <Flex
            direction="column"
            align="stretch"
            gap="1rem"
          >
            <Typography>Rellena los datos de la persona que solicitó ayuda con su orden médica:</Typography>
            <Flex direction="column">
              <Select
                label="País"
                id="country"
                disabled={loading}
                value={medicalOrderRequest.country}
                onChange={(e) => {
                  cleanErrors();
                  setMedicalOrderRequest({
                    ...medicalOrderRequest,
                    country: e.target.value as Locale,
                  });
                }}
                fullWidth
              >
                {COUNTRIES.map((country) => (
                  <MenuItem
                    key={country}
                    value={country}
                  >
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </Flex>
            <TextField
              id="name"
              helperText={messageErrors.name || "Nombre"}
              error={messageErrors.name != null}
              disabled={loading}
              value={medicalOrderRequest.name}
              onChange={(e) => {
                cleanErrors();
                setMedicalOrderRequest({
                  ...medicalOrderRequest,
                  name: e.target.value,
                });
              }}
            />
            <TextField
              id="phone"
              helperText={messageErrors.phone || "Teléfono"}
              error={messageErrors.phone != null}
              disabled={loading}
              value={medicalOrderRequest.phone}
              onChange={(e) => {
                cleanErrors();
                setMedicalOrderRequest({
                  ...medicalOrderRequest,
                  phone: e.target.value,
                });
              }}
            />
            <TextField
              id="email"
              helperText={messageErrors.email || "Email (opcional)"}
              error={messageErrors.email != null}
              disabled={loading}
              value={medicalOrderRequest.email}
              onChange={(e) => {
                cleanErrors();
                setMedicalOrderRequest({
                  ...medicalOrderRequest,
                  email: e.target.value,
                });
              }}
            />
            <Flex direction="column">
              {fileArray?.length == 0 && (
                <div>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={handleClick}
                  >
                    Seleccionar archivo
                  </Button>
                  <input
                    type="file"
                    accept="application/pdf, image/*"
                    disabled={loading}
                    onChange={handleFileInputChange}
                    ref={inputRef}
                    hidden
                  />
                </div>
              )}
              {fileArray && fileArray?.length > 0 && renderFileUploadProgressBar()}
            </Flex>
            <Typography color="secondary">{error}</Typography>
          </Flex>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleUploadData}
            color="primary"
          >
            {loading ? "Creando..." : "Crear"}
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              cleanErrors();
              setMedicalOrderRequest({
                name: "",
                email: "",
                phone: "",
                country: "Chile",
                triggered_from: "betty-request",
                sales_source: "betty-request",
              });
              setOpen(false);
            }}
            color="secondary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadMedicalOrderRequest;
